





































































    import { Component, Vue, Prop } from 'vue-property-decorator';
    import axios from "axios";
    import { AxiosRequestConfig } from "axios";
	import { MainMenu } from '@/config/Text.json';
	import { internet } from '@/utils/Internet';
	import Util from '@/utils/Util';
    
    @Component({
        name: 'Card'
    })
    
    export default class Card extends Vue {
		@Prop({
        type: String,
        required: true,
		})
		public fechai: any;
		@Prop({
			type: String,
			required: true,
		})
		public fechaf: any;
		@Prop({
			type: String,
			required: true,
		})
		public campania: any;
		public agente = '';
		public cats: Array<string> = [];
		public cards: any = {};
        public cardTAS = 0;
        public total = 0;

        // i18n variables
        public message1 = this.$t("Cards.msgCard1");
        public message2 = this.$t("Cards.msgCard2");
        public message3 = this.$t("Cards.msgCard3");
        public message4 = this.$t("Cards.msgCard4");
        public message5 = this.$t("Cards.msgCard5");
        public message6 = this.$t("Cards.msgCard6");
        public message7 = this.$t("Cards.msgCard7");

        mounted(): void{
			if (localStorage.getItem('user') != null) {
				this.agente = localStorage.getItem('user') as string;
			}
			if (this.fechai != "") {
				this.getCards();
			}
        }

		getIconForPosition(index: number): string {
			return MainMenu.icons[index];
		}

        public getCards() {
			const request = internet
				.newRequest()
				.get("vistaAgente/getCards?agente=" + this.agente + "&campania="+ this.campania + '&fechai=' + this.fechai + '&fechaf=' + this.fechaf)
			
			Util.waitForPromises([request])
            .then((response) => {
                let datos = response[0].data;
				let num = 0;
                for (let item of datos) {
                    let itemid = Object.values(item._id);	
                    let cat = itemid[1] as string;
					if (!(cat in Object.keys(this.cards))) {
						this.cards[cat] = {'nombre': itemid[0], 'promedio': item.Promedio}
						num += 1;
					} else {
						this.cards[cat]['promedio'] += item.Promedio;
					}
					this.total = item.Total;
                }
                for (let cat of Object.keys(this.cards)) {
					this.cardTAS += this.cards[cat]['promedio'];
				}
				this.cardTAS = this.cardTAS/num;
            })
        }
    }
